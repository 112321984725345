import React,{useEffect,useState,useRef} from 'react';

const NavBar1 = ({change}) => {
    const [sel, setSel] = useState(1);
    const _cha = (c)=>{
        setSel(c);
        change(c);
    }
        return (
            <div className='finav tac br pad5'>
                        <button onClick={()=>_cha(1)} className={`ani1 nSel w100 br ${sel===1 && 'nSelAct'}`}>2D</button>
                        <button onClick={()=>_cha(2)} className={`ani1 nSel w100 br ${sel===2 && 'nSelAct'}`}>3D</button>
                        <button onClick={()=>_cha(3)} className={`ani1 nSel w100 br ${sel===3 && 'nSelAct'}`}>AR</button>
            </div>
        )
    }


export default NavBar1;